<template>
    <div class="page page-upgrade">
        <TopHeader />
        
        <main>
            <header class="container page-header mt64">
                <h1 class="page-title mb16">Planos simples e transparentes</h1>
                <p class="page-intro">Lembre-se, somos uma empresa brasileira. Você paga sempre em real, sem taxas internacionais ou variação cambial. Oferecemos boleto para plano anual.</p>
            </header>

            <section class="container container-lg price-table mt64">
                <PriceTable :upgrade="true" />
            </section>

            <section class="container container-lg price-table mt64 hidden">

                <!-- Free -->
                <div class="plan-item plan-free">
                    <h2 class="plan-title mb8">Grátis</h2>
                    <h3 class="plan-price mb4">R$ 0,00 <span class="period">/ mês</span></h3>
                    <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
                    <p class="plan-descriptio mt16 mb32">Para pequenos projetos pessoais que estão iniciando</p>
                    <!-- <Btn type="secondary" :softDisabled="true" class="mb32">Plano atual</Btn> -->
                    <!-- <Btn class="mb32">Começar →</Btn> -->
                    <ul class="feature-list">
                        <li class="feature-item"><Icon name="check" class="check-icon" /> 100 respostas por mês</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> 5 formulários</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 100MB em arquivos</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Busca de CEP</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Remover a nossa marca</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Validação de CPF e CNPJ</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Google Analytics</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Facebook Pixel</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Múltiplos usuários</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Times e Permissões</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Domínio customizado</li>
                    </ul>
                </div>

                <!-- Solo -->
                <div class="plan-item plan-solo">
                    <h2 class="plan-title mb8">Solo</h2>
                    <h3 class="plan-price mb4">R$ 57,00 <span class="period">/ mês</span></h3>
                    <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
                    <p class="plan-descriptio mt16 mb32">Para projetos pessoais que precisam de mais recursos</p>                    
                    <!-- <Btn @click.native="redirectToCheckout('solo')" :loading="working.sololink" class="mb32">Assinar →</Btn> -->
                    <ul class="feature-list">
                        <li class="feature-item"><Icon name="check" class="check-icon" /> 1.000 respostas por mês</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Ilimitado</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 1 GB em arquivos</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Busca de CEP</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Remover a nossa marca</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Validação de CPF e CNPJ</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Google Analytics</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Facebook Pixel</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Múltiplos usuários</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Times e Permissões</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Domínio customizado</li>
                    </ul>
                </div>

                <!-- PRO -->
                <div class="plan-item plan-pro">
                    <h2 class="plan-title mb8">PRO</h2>
                    <h3 class="plan-price mb4">R$ 87,00 <span class="period">/ mês</span></h3>
                    <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
                    <p class="plan-descriptio mt16 mb32">Para projetos profissionais com campos exclusivos</p>
                    <!-- <Btn @click.native="redirectToCheckout('pro')" :loading="working.prolink" class="mb32">Assinar →</Btn> -->
                    <ul class="feature-list">
                        <li class="feature-item"><Icon name="check" class="check-icon" /> 5.000 respostas por mês</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Ilimitado</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 5 GB em arquivos</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Busca de CEP</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Remover a nossa marca</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Validação de CPF e CNPJ</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Google Analytics</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Facebook Pixel</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Múltiplos usuários</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Times e Permissões</li>
                        <li class="feature-item feature-disabled"><Icon name="times" /> Domínio customizado</li>
                    </ul>
                </div>

                <!-- Empresa -->
                <div class="plan-item plan-business">
                    <h2 class="plan-title mb8">Empresa</h2>
                    <h3 class="plan-price mb4">R$ 247,00 <span class="period">/ mês</span></h3>
                    <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
                    <p class="plan-descriptio mt16 mb32">Para empresas e times que precisam colaborar</p>
                    <!-- <Btn @click.native="redirectToCheckout('business')" :loading="working.businesslink" class="mb32">Assinar →</Btn> -->
                    <ul class="feature-list">
                        <li class="feature-item"><Icon name="check" class="check-icon" /> 15.000 respostas por mês</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Ilimitado</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 10 GB em arquivos</li>                        
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Busca de CEP</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Remover a nossa marca</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Validação de CPF e CNPJ</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Google Analytics</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Facebook Pixel</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Múltiplos usuários</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Times e Permissões</li>
                        <li class="feature-item"><Icon name="check" class="check-icon" /> Domínio customizado <TagLabel type="beta">Breve</TagLabel></li>
                    </ul>
                </div>

            </section>

            <section class="container other-options mt128 mb64">
                <div class="option-item pay-boleto mr64">
                    <h3 class="option-title mb8">Boleto bancário?</h3>
                    <p>Nós aceitamos pagamento com boleto bancário nos planos anuais, <a class="link" href="https://respondi.app/KUNqz58b">solicite aqui</a>.</p>
                </div>
                <div class="option-item need-more">
                    <h3 class="option-title mb8">Precisa de mais recursos?</h3>
                    <p>Se você precisa de um número maior de recursos, <span onclick="Beacon('open'); Beacon('navigate', '/ask/message')" class="link">converse com a gente</span> sobre um plano customizado.</p>
                </div>
            </section>


            <!-- Plans Description -->
            <section class="container plans-description mt64 hidden">
                <h2>Conheça todas as funcionalidades</h2>

                <ul class="feature-table">
                    <li class="feature-item">
                        <div class="feature-name">Plano</div>
                        <div class="feature-plan plan-free">Grátis</div>
                        <div class="feature-plan plan-solo">Solo</div>
                        <div class="feature-plan plan-pro">PRO</div>
                        <div class="feature-plan plan-business">Empresa</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Respostas mensais</div>
                        <div class="feature-plan plan-free">10</div>
                        <div class="feature-plan plan-solo">1.000</div>
                        <div class="feature-plan plan-pro">5.000</div>
                        <div class="feature-plan plan-business">15.000</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Formulários</div>
                        <div class="feature-plan plan-free">5</div>
                        <div class="feature-plan plan-solo">Ilimitado</div>
                        <div class="feature-plan plan-pro">Ilimitado</div>
                        <div class="feature-plan plan-business">Ilimitado</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Suporte em portugês</div>
                        <div class="feature-plan plan-free">Sim</div>
                        <div class="feature-plan plan-solo">Sim</div>
                        <div class="feature-plan plan-pro">Sim</div>
                        <div class="feature-plan plan-business">Sim</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Personalização de cores e logotipo</div>
                        <div class="feature-plan plan-free">Sim</div>
                        <div class="feature-plan plan-solo">Sim</div>
                        <div class="feature-plan plan-pro">Sim</div>
                        <div class="feature-plan plan-business">Sim</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Remover marca Respondi.app</div>
                        <div class="feature-plan plan-free">Não</div>
                        <div class="feature-plan plan-solo">Não</div>
                        <div class="feature-plan plan-pro">Sim</div>
                        <div class="feature-plan plan-business">Sim</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Campo de busca de CEP</div>
                        <div class="feature-plan plan-free">Não</div>
                        <div class="feature-plan plan-solo">Sim</div>
                        <div class="feature-plan plan-pro">Sim</div>
                        <div class="feature-plan plan-business">Sim</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Campo de CPF ou CNPJ</div>
                        <div class="feature-plan plan-free">Não</div>
                        <div class="feature-plan plan-solo">Não</div>
                        <div class="feature-plan plan-pro">Sim</div>
                        <div class="feature-plan plan-business">Sim</div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-name">Receber upload de arquivos</div>
                        <div class="feature-plan plan-free">100 MBs</div>
                        <div class="feature-plan plan-solo">1 GB</div>
                        <div class="feature-plan plan-pro">5 GB</div>
                        <div class="feature-plan plan-business">10 GB</div>
                    </li>
                </ul>
                
            </section>
        </main>


    </div>
</template>


<style lang="sass" scoped>
.container
    max-width: 900px
    margin: auto

    &.container-lg
        max-width: 1200px

.hidden
    display: none


.page-upgrade
    padding-bottom: $mgLg

    .page-header
        max-width: 580px
        margin: auto
        text-align: center

        .page-title
            font-size: $fzXl

        .page-description
            font-size: $fzSm

    
    .feature-table
        .feature-item
            display: flex

            &:hover
                background: $cG1

            .feature-name
                padding: $mgSm
                padding-left: 0            
                flex: 3
            
            .feature-plan
                padding: $mgSm            
                flex: 1
                text-align: center


    .other-options
        display: flex

        .option-item
            flex: 1

            .option-title
                font-size: $fzMd
    

</style>

<script>
import PriceTable from '../components/PriceTable.vue'
import TagLabel from '../components/TagLabel.vue'
import Btn from '../components/form/Btn.vue'
import Icon from '../components/Icon.vue'
import TopHeader from '../components/TopHeader.vue'
    export default {
        components: { TopHeader, Icon, Btn, TagLabel, PriceTable },
    }
</script>