<template>
    <section class="price-table-comp">

        <!-- Free -->
        <div class="plan-item plan-free">
            <h2 class="plan-title mb8">Grátis</h2>
            <h3 class="plan-price mb4">R$ 0,00 <span class="period">/ mês</span></h3>
            <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
            <p class="plan-descriptio mt16 mb32">Para pequenos projetos pessoais que estão iniciando</p>
            <Btn v-if="upgrade" type="secondary" :softDisabled="true" class="mb32">Plano atual</Btn>
            <Btn v-else @click.native="redirectToCheckout('free')" class="mb32">Comece grátis →</Btn>
            <!-- <Btn class="mb32">Começar →</Btn> -->
            <ul class="feature-list">
                <li class="feature-item"><Icon name="check" class="check-icon" /> 100 respostas por mês</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> 3 formulários</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 100MB em arquivos</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Busca de CEP</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Remover a nossa marca</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Validação de CPF e CNPJ</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Google Analytics</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Facebook Pixel</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Múltiplos usuários</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Times e Permissões</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Domínio customizado</li>
            </ul>
        </div>

        <!-- Solo -->
        <div class="plan-item plan-solo">
            <h2 class="plan-title mb8">Solo</h2>
            <h3 class="plan-price mb4">R$ 57,00 <span class="period">/ mês</span></h3>
            <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
            <p class="plan-descriptio mt16 mb32">Para projetos pessoais que precisam de mais recursos</p>                    
            <Btn @click.native="redirectToCheckout('solo')" :loading="working.sololink" class="mb32">Assinar →</Btn>
            <ul class="feature-list">
                <li class="feature-item"><Icon name="check" class="check-icon" /> 1.000 respostas por mês</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Formulários ilimitados</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 1 GB em arquivos</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Busca de CEP</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Remover a nossa marca</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Validação de CPF e CNPJ</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Google Analytics</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Facebook Pixel</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Múltiplos usuários</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Times e Permissões</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Domínio customizado</li>
            </ul>
        </div>

        <!-- PRO -->
        <div class="plan-item plan-pro">
            <h2 class="plan-title mb8">PRO</h2>
            <h3 class="plan-price mb4">R$ 87,00 <span class="period">/ mês</span></h3>
            <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
            <p class="plan-descriptio mt16 mb32">Para projetos profissionais com campos exclusivos</p>
            <Btn @click.native="redirectToCheckout('pro')" :loading="working.prolink" class="mb32">Assinar →</Btn>
            <ul class="feature-list">
                <li class="feature-item"><Icon name="check" class="check-icon" /> 5.000 respostas por mês</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Formulários ilimitados</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 5 GB em arquivos</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Busca de CEP</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Remover a nossa marca</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Validação de CPF e CNPJ</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Google Analytics</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Facebook Pixel</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Múltiplos usuários</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Times e Permissões</li>
                <li class="feature-item feature-disabled"><Icon name="times" /> Domínio customizado</li>
            </ul>
        </div>

        <!-- Empresa -->
        <div class="plan-item plan-business">
            <h2 class="plan-title mb8">Empresa</h2>
            <h3 class="plan-price mb4">R$ 247,00 <span class="period">/ mês</span></h3>
            <p class="plan-billed">Cobrado R$ 650,00 anualmente</p>
            <p class="plan-descriptio mt16 mb32">Para empresas e times que precisam colaborar</p>
            <Btn @click.native="redirectToCheckout('business')" :loading="working.businesslink" class="mb32">Assinar →</Btn>
            <ul class="feature-list">
                <li class="feature-item"><Icon name="check" class="check-icon" /> 15.000 respostas por mês</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Formulários ilimitados</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Recebe até 10 GB em arquivos</li>                        
                <li class="feature-item"><Icon name="check" class="check-icon" /> Personalize cores e logotipo</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Suporte em português</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Busca de CEP</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Remover a nossa marca</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Validação de CPF e CNPJ</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Google Analytics</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Facebook Pixel</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Múltiplos usuários</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Times e Permissões</li>
                <li class="feature-item"><Icon name="check" class="check-icon" /> Domínio customizado <TagLabel type="beta">Breve</TagLabel></li>
            </ul>
        </div>

    </section>
</template>

<script>
import PriceTable from '../components/PriceTable.vue'
import TagLabel from '../components/TagLabel.vue'
import Btn from '../components/form/Btn.vue'
import Icon from '../components/Icon.vue'
import TopHeader from '../components/TopHeader.vue'
    export default {
        name: "PriceTable",
        components: { TopHeader, Icon, Btn, TagLabel, PriceTable },
        props: {
            upgrade: Boolean,
        },
        data() {
            return {
                working: {
                    sololink: false,
                    prolink: false,
                    businesslink: false,
                }
            }
        },       


        methods: {

            redirectToCheckout(plan){
                if(!this.upgrade) return window.location = this.createPublicCheckoutLink(plan)
                else this.createCheckoutSession(plan)           
            },

            createPublicCheckoutLink(plan){
                return this.$site.url + '/signup/?checkout=true&plan=' + plan
            },

            createCheckoutSession(plan){
                let price
                if(plan == 'pro'){
                    price = process.env.VUE_APP_STRIPE_PRO_PLAN
                    this.working.prolink = true
                } 
                if(plan == 'solo'){
                    price = process.env.VUE_APP_STRIPE_SOLO_PLAN
                    this.working.sololink = true
                }
                if(plan == 'business'){
                    price = process.env.VUE_APP_STRIPE_BUSINESS_PLAN
                    this.working.businesslink = true
                }

                const data = {price}

                this.$axios
                .post(process.env.VUE_APP_API_URL + 'subscription/checkoutsession/' + this.$user.id, data)
                .then(response =>{
                    window.location = response.data.data.url
                })

            }

        },
    }
</script>

<style lang="sass" scoped>
.price-table-comp
        display: flex
        flex-wrap: wrap
        justify-content: center

        .plan-item
            background: $cWhite
            padding: $mgSm
            font-size: $fzXs
            border-radius: 5px
            margin-right: $mg
            width: 100%
            max-width: 260px
            border: 1px solid $cG2
            // box-shadow: 0 0 30px rgba(0,0,0,.1)
            position: relative
            transition: all 2s
            
            &:hover                
                box-shadow: 0 0 30px rgba(0,0,0,.15)
                transition: all .3s
                // background: $cG1

        .plan-title
            font-size: $fzSm
            font-weight: bold

        .plan-price
            font-size: $fzXl
            font-weight: normal
            letter-spacing: -1px

            .period
                font-size: $fzSm

        .plan-billed
            font-size: $fzXxs
            display: none
            // color: $cG5
        
        .plan-description
            color: $cG5

        .feature-item
            margin-bottom: $mgSm

            &.feature-disabled
                opacity: .5


        .plan-star
            outline: 2px solid $cPositive

            &:before
                content: "+ Popular"
                display: block
                position: absolute
                top: -22px
                left: 75px
                background: $cPositive
                border-radius: 5px 5px 0 0
                color: $cWhite
                padding: 4px 10px

        .plan-free
            .plan-title
                color: $cG5

        .plan-solo
            .plan-title
                color: #448AFF

        .plan-pro
            .plan-title
                color: #7C4DFF

        .plan-business
            .plan-title
                color: #8D6E63

        .check-icon
            color: $cPositive

        .other-options
            display: flex

            .option-item
                flex: 1

                .option-title
                    font-size: $fzMd

</style>
